<template>
  <div id="home">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="title">{{ $t('menu.dashboard') }}</div>
        <Rebate ref="rebate" :userID="userID" @accountIdAndCurrency="getAccountIdAndCurrency"></Rebate>
        <div class="query">
          <div class="calendar_content flex flex-center">
            <dateRangePicker v-bind:start-date.sync="startTime" v-bind:end-date.sync="endTime"></dateRangePicker>

            <el-button class="ib-button" plain @click="setStartAndEndDate" data-testid="UPDATE">
              {{ $t('common.keys.UPDATE') }}
            </el-button>
          </div>
          <ul class="theme_box flex flex-between">
            <BlockView
              :title="$t('home.Rebate')"
              data-testid="REBATE"
              :changeStatus="() => (currLineChartStatus = 'REBATE')"
              :val="accountDatas.totalCommission | currency('')"
              :icon="require('@/assets/uploads/rebate.png')"
              :class="{ active: currLineChartStatus === 'REBATE' }"
              :currAccountCurrencyFilter="currAccountCurrency | currencySymbol"
            />
            <BlockView
              data-testid="TOTAL_VOLUME"
              :changeStatus="() => (currLineChartStatus = 'TOTAL_VOLUME')"
              :title="$t('home.TOTAL_VOLUME')"
              :val="accountDatas.totalVolume | currency('')"
              :icon="require('@/assets/uploads/volume.png')"
              :class="{ active: currLineChartStatus === 'TOTAL_VOLUME' }"
            />

            <BlockView
              data-testid="NET_FUNDING"
              :class="{ active: currLineChartStatus === 'NET_FUNDING' }"
              :changeStatus="() => (currLineChartStatus = 'NET_FUNDING')"
              :title="$t('home.NET_FUNDING')"
              :val="accountDatas.netDeposit | currency('')"
              :icon="require('@/assets/uploads/netfunding.png')"
              :currAccountCurrencyFilter="currAccountCurrency | currencySymbol"
            />

            <BlockView
              data-testid="DEPOSITS"
              :class="{ active: currLineChartStatus === 'DEPOSITS' }"
              :changeStatus="() => (currLineChartStatus = 'DEPOSITS')"
              :title="$t('home.DEPOSITS')"
              :val="accountDatas.totalDeposit | currency('')"
              :icon="require('@/assets/uploads/deposts.png')"
              :currAccountCurrencyFilter="currAccountCurrency | currencySymbol"
            />
            <BlockView
              data-testid="WITHDRAW"
              :class="{ active: currLineChartStatus === 'WITHDRAW' }"
              :changeStatus="() => (currLineChartStatus = 'WITHDRAW')"
              :title="$t('home.WITHDRAW')"
              :val="accountDatas.totalWithdrawal | currency('')"
              :icon="require('@/assets/uploads/widthdraw.png')"
              :currAccountCurrencyFilter="currAccountCurrency | currencySymbol"
            />

            <BlockView
              data-testid="OPEND_ACCOUNT"
              :class="{ active: currLineChartStatus === 'OPEND_ACCOUNT' }"
              :changeStatus="() => (currLineChartStatus = 'OPEND_ACCOUNT')"
              :title="$t('home.OPEND_ACCOUNT')"
              :val="accountDatas.newOpenUserTotal"
              :icon="require('@/assets/uploads/accounts.png')"
            />
          </ul>
        </div>
        <Chart ref="chart" :accountID="accountID" :startTime="startTime" :endTime="endTime" :userID="userID"></Chart>
        <el-row :gutter="15" class="tables">
          <el-col :xs="24" :sm="12" class="mb-2">
            <div class="table">
              <div class="item_header">
                {{ $t('home.TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH') }}
              </div>
              <div class="table_list_content">
                <el-table :data="top6" height="100%" style="width: 100%">
                  <el-table-column prop="agentLowAccount" :label="$t('home.Account') + ' No'"></el-table-column>
                  <el-table-column prop="name" :label="$t('home.Name')">
                    <template slot-scope="scope">
                      <span v-textOverflow v-html="scope.row.name" />
                    </template>
                  </el-table-column>
                  <el-table-column prop="volume" :label="$t('home.Volume')">
                    <template slot-scope="topPerformScope">{{ topPerformScope.row.volume | currency('') }}</template>
                  </el-table-column>
                  <!-- <el-table-column prop="commission" :label="$t('home.Rebate')">
                    <template slot-scope="topPerformScope">
                      {{ topPerformScope.row.commission | currency('') }}
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" class="mb-2">
            <div class="table">
              <div class="item_header">
                {{ $t('home.RECENTLY_OPENED_ACCOUNTS') }}
              </div>
              <div class="table_list_content">
                <el-table :data="nearestOpenAccount" height="100%" style="width: 100%">
                  <el-table-column prop="tradeAccount" :label="$t('home.Account') + ' No'"></el-table-column>
                  <el-table-column prop="userName" :label="$t('home.Name')">
                    <template slot-scope="scope">
                      <span v-textOverflow v-html="scope.row.userName"></span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="balance" :label="$t('home.Balance')">
                    <template slot-scope="scope">
                      <!-- -0.00000009表示后台未查到数据，则用 - 显示 -->
                      <span v-if="scope.row.balance == -0.00000009">-</span>
                      <span v-else>{{ scope.row.balance | currency('') }}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column :label="$t('home.ContactClient')">
                    <template>
                      <img style="vertical-align: middle" src="@/assets/uploads/contactUs/chat.png" alt />
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- AWC Dialog 弹框开始 -->
    <!-- <AWCDialog :AWCVisible.sync="AWCVisible"> </AWCDialog> -->
    <!-- AWC Dialog 弹框结束 -->
  </div>
</template>

<script>
import Chart from './chart';
import Rebate from '@/components/Rebate';
import DateRangePicker from '@/components/DateRangePicker';
import AWCDialog from '@/components/home/AWCDialog';
import BlockView from '@/components/BlockView';
import {
  apiGetNearestOpenAccount,
  apiQueryRebateVolumeList,
  apiQueryTotalCommission,
  apiGetNewOpenUserTotal
} from '@/resource';
export default {
  name: 'Home',
  components: {
    Chart,
    Rebate,
    DateRangePicker,
    AWCDialog,
    BlockView
  },
  data() {
    return {
      userID: this.$store.state.common.CUID,
      startTime: '',
      endTime: '',
      top6: [],
      nearestOpenAccount: [],
      accountID: '',
      currAccountCurrency: '',
      AWCVisible: false,
      accountDatas: {
        // 总手数
        totalVolume: 0,
        // 总入金
        totalDeposit: 0,
        // 净入金
        netDeposit: 0,
        // 总出金
        totalWithdrawal: 0,
        // 总佣金
        totalCommission: 0,
        // 可用余额
        availableBalance: 0,
        // 新开账号数
        newOpenUserTotal: 0
      },
      // 当前显示的什么标签的趋势图
      currLineChartStatus: 'REBATE'
    };
  },
  mounted() {
    this.checkLoginStatus();
  },
  methods: {
    initData() {
      if (this.accountID) {
        Promise.all([
          this.getNearestOpenAccount(),
          this.queryRebateVolumeList(),
          this.queryTotalCommission(),
          this.getNewOpenUserTotal()
        ]);
      }
      // 每次请求数据，折线图恢复为默认字段
      this.currLineChartStatus = 'REBATE';
      // 更新折线图
      this.$nextTick(() => {
        this.$refs.chart.updateLineChart(this.currLineChartStatus);
        this.$refs.chart.updatePieChart();
      });
    },
    // 获取 最新6个开户账号
    getNearestOpenAccount() {
      apiGetNearestOpenAccount({
        mt4account: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) this.nearestOpenAccount = resp.data.data;
      }, this.$handleError);
    },
    // 获取佣金top6
    queryRebateVolumeList() {
      apiQueryRebateVolumeList({
        qUserId: this.userID,
        qAccount: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) this.top6 = resp.data.data;
      }, this.$handleError);
    },
    // 获取 佣金 交易量 净入金 总入金 总出金
    queryTotalCommission() {
      apiQueryTotalCommission({
        qUserId: this.userID,
        qAccount: this.accountID,
        qStartTime: this.startTime,
        qEndTime: this.endTime
      }).then(resp => {
        if (resp.data.code === 0) {
          const respData = resp.data.data;
          Object.keys(respData).map(key => {
            this.$set(this.accountDatas, key, respData[key]);
          });
        }
      }, this.$handleError);
    },
    // 获取 新开账号数
    getNewOpenUserTotal() {
      apiGetNewOpenUserTotal({
        mt4account: this.accountID,
        startTime: this.startTime,
        endTime: this.endTime
      }).then(resp => {
        if (resp.data.code === 0) this.$set(this.accountDatas, 'newOpenUserTotal', resp.data.data);
      }, this.$handleError);
    },
    setStartAndEndDate() {
      this.$nextTick(() => this.initData());
    },
    getAccountIdAndCurrency(accountID, currency) {
      this.accountID = accountID;
      this.currAccountCurrency = currency;
      if (this.startTime && this.endTime) this.initData();
    },
    goToIbReport() {
      const cardMethod = this.currLineChartStatus == 'OPEND_ACCOUNT' ? `cardMethod=openedAccounts` : '';
      this.$router.push(`ibReport?${cardMethod}`);
    },
    goToRebateReport() {
      this.$router.push(`rebateReport`);
    },
    checkLoginStatus() {
      if (this.firstLogin && (this.regulator === 'fsc' || this.regulator === 'scb')) {
        this.$store.commit('common/setFirstLogin', false);
        this.AWCVisible = true;
      }
    }
  },
  watch: {
    currLineChartStatus(newStatus) {
      this.$nextTick(() => this.$refs.chart.updateLineChart(newStatus));
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    firstLogin() {
      return this.$store.state.common.firstLogin;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/defaultDatePickerLayout.scss';
@import '@/assets/css/pages/home/index.scss';
</style>
