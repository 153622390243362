<template>
  <li class="box-btn flex cursor-pointer" @click="changeStatus">
    <div class="icon-container flex flex-center flex-middle">
      <img :src="icon" />
    </div>
    <div class="word-container flex flex-center">
      <span data-v-454c4b67="">{{ title }}</span>
      <p data-v-454c4b67="">{{ currAccountCurrencyFilter + val }}</p>
    </div>
  </li>
</template>
<script>
export default {
  props: {
    title: String,
    val: [Number, String],
    icon: String,
    currAccountCurrencyFilter: {
      type: String,
      default: ''
    },
    changeStatus: {
      type: Function,
      default: () => {}
    }
  }
};
</script>
<style scoped lang="scss">
.box-btn {
  height: 134px;
  background-color: $theme-hue;
  border-radius: 10px;
  overflow: hidden;


  margin-top: 20px;
  &.active {
    background: #121212;
  }
  .icon-container {
    height: 134px;
    width: 134px;
    background-color: $black1;
    flex-shrink: 0;
    img {
      width: 60px;
    }
  }
  .word-container {
    flex-direction: column;
    padding: 20px 20px;
    span {
      color: $white;
      font-size: 16px;
      font-weight: 300;
    }
    p {
      color: $white;
      font-size: 20px;
      margin-top: 5px;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
</style>
