<template>
  <vDialog :visible.sync="visible" :visibleFooter="false" width="605px" top="15%">
    <div class="banner" v-if="isCommon">
      <img src="@/assets/uploads/awc/close.png" class="closeImg" alt @click="visible = false" data-testid="closeImg" />
      <img :src="getBanner()" class="banner_img" alt="app banner" />
      <div class="btn" :class="{ btn_ar: lang === 'ar' }">
        <a class="el-button" :href="`https://partners.infinoxmarkets.com${getLanguage()}/affiliate-world-cup-2022`">
          {{ $t('AWCDialog.optIn') }}<em class="el-icon-arrow-right"></em>
        </a>
        <a class="el-button el-button--primary" @click="visible = false">
          {{ $t('AWCDialog.noThanks') }}
        </a>
      </div>
    </div>
    <div class="brazil_banner" v-if="isBrazilIB">
      <img src="@/assets/uploads/awc/close.png" class="closeImg" alt @click="visible = false" data-testid="closeImg" />
      <img src="@/assets/uploads/awc/banner-br.png" class="banner_img" alt="app banner" />
      <div class="brazil_content">
        <div>
          <p class="title3">{{ $t('CFD do Índice Bovespa (BVSPX)') }}</p>
          <p class="title4">
            {{ $t('DIVERSIFIQUE seu portfólio negociando um dos principais índices da América Latina') }}
          </p>
          <Button class="brazil_btn">
            <a href="https://www.infinoxmarkets.com/markets/indices/" target="_blank">
              <span class="btn_text"
                >{{ $t('NEGOCIE O ÍNDICE BOVESPA &nbsp;AGORA ') }} <span class="suffix">></span>
              </span>
            </a>
          </Button>
        </div>
      </div>
    </div>
  </vDialog>
</template>

<script>
import vDialog from '@/components/home/vDialog';
import { CheckboxGroup } from 'element-ui';

export default {
  name: 'AWCDialog',
  props: {
    AWCVisible: Boolean
  },
  components: { vDialog },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    close() {
      this.$emit('update:AWCVisible', false);
    },
    getBanner() {
      if (this.$store.state.common.countryCode === '3636') require('@/assets/uploads/awc/banner-br.png');
      else return require('@/assets/uploads/awc/banner-' + this.lang + '.png');
    },
    getLanguage() {
      switch (this.lang) {
        case 'zh_CN':
          return '/zh-hant';
        case 'vi':
          return '/vi-vn';
        case 'ko':
          return '/ko-kr';
        case 'ar':
          return '/ar';
        case 'en_US':
          return '';
        default:
          return '/' + this.lang + '-' + this.lang;
      }
    }
  },
  watch: {
    AWCVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:AWCVisible', bool);
    }
  },
  computed: {
    isBrazilIB() {
      return parseInt(this.$store.state.common.countryCode) === 3636;
    },
    isCommon(){
      return parseInt(this.$store.state.common.countryCode) !== 3636;
    },
    lang() {
      if (this.$store.state.common.lang === 'ja') return 'en_US';
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home/AWCDialog.scss';
</style>
